<template>
  <div>
    <v-form v-model="mainFormValid" class="pa-4">
      <v-text-field
        v-model="internalValue.name"
        :rules="$options.rules.name"
        label="Nom"
        type="text"
        @input="change"
      />

      <v-file-input
        :rules="fileRequired ? $options.rules.file : []"
        show-size
        label="Fichier"
        @change="readFile($event)"
      />
    </v-form>
  </div>
</template>

<script>
import Helpers from 'src/utils/helpers'
import FormRules from 'src/utils/form-rules'

export default {
  name: 'DocumentForm',
  components: { },
  props: {
    value: {
      type: Object,
      required: true,
    },
    fileRequired: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    internalValue: {
      name: '',
      file: null,
    },
    mainFormValid: false,
  }),
  computed: {
    isValidDocument () {
      return this.mainFormValid
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (!value.file) this.internalValue = Helpers.clone(value)
        else {
          this.internalValue.name = Helpers.clone(value.name)
          if (!this.fileRequired) this.internalValue._id = Helpers.clone(value._id)
        }
        this.$emit('validate', this.isValidDocument)
      },
    },
    isValidDocument: {
      immediate: true,
      handler () {
        this.$emit('validate', this.isValidDocument)
      },
    },
  },
  methods: {
    readFile (event) {
      this.internalValue.file = event
      this.change()
    },
    change () {
      this.$emit('input', this.internalValue)
      this.$emit('validate', this.isValidDocument)
    },
  },
  rules: {
    name: [
      FormRules.required('requis'),
    ],
    file: [
      FormRules.required('requis'),
    ],
  },
}
</script>
