<template>
  <ag-modal-container
    title="Ajouter un document"
    width="800px"
    @close="back"
  >
    <document-form
      v-model="value"
      file-required
      level-choice
      @validate="isValid = $event"
    />

    <template v-slot:footer>
      <v-spacer />
      <v-btn
        :loading="loading"
        :disabled="!isValid"
        color="accent"
        class="ml-5"
        @click="create"
        v-text="'Ajouter'"
      />
      <v-spacer />
    </template>
  </ag-modal-container>
</template>

<script>
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'

import AgModalContainer from 'src/components/common/AgModalContainer'
import DocumentForm from '../components/DocumentForm'

export default {
  name: 'DocumentCreateContainer',
  components: {
    AgModalContainer,
    DocumentForm,
  },
  data: () => ({
    isValid: false,
    loading: false,
    value: {
      name: '',
      file: null,
    },
  }),
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    back () {
      this.$router.back()
    },
    async create () {
      this.loading = true
      try {
        await Repositories.document.post({ ...this.value, ...{ isFormData: true } })
        this.showSuccessNotification(`Le document ${this.value.name} a bien été créé`)
        this.back()
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loading = false
      }
    },
  },
}
</script>
